/**
 * 工具类  Update by oy 2020-8-25
 */
var SIGN_REGEXP = /([yMdhsm])(\1*)/g;
var DEFAULT_PATTERN = 'yyyy-MM-dd';

function padding(s, len) {
	let l = len - (s + '').length;
	for (var i = 0; i < l; i++) {
		s = '0' + s;
	}
	return s;
}

export default {
	previewBlobFile: function(res, fileName) {
		if (res instanceof Blob) {
			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(res, fileName);
			} else {
				const blob = res;
				const a = document.createElement('a');
				a.href = URL.createObjectURL(blob);
				window.open(a);
			}
		} else {
			console.log('error');
		}
	},

	/**
	 * 获取 blob
	 * @param  {String} url 目标文件地址
	 * @return {cb}
	 */
	getBlob(url, cb) {
		var xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		xhr.responseType = 'blob';
		xhr.onload = function() {
			if (xhr.status === 200) {
				cb(xhr.response);
			}
		};
		xhr.send();
	},

	/**
	 * 保存
	 * @param  {Blob} blob
	 * @param  {String} filename 想要保存的文件名称
	 */
	saveAs(blob, filename) {
		if (window.navigator.msSaveOrOpenBlob) {
			navigator.msSaveBlob(blob, filename);
		} else {
			var link = document.createElement('a');
			var body = document.querySelector('body');
			link.href = window.URL.createObjectURL(blob);
			link.download = filename;
			// fix Firefox
			link.style.display = 'none';
			body.appendChild(link);
			link.click();
			body.removeChild(link);
			window.URL.revokeObjectURL(link.href);
		}
	},

	/**
	 * 下载
	 * @param  {String} url 目标文件地址
	 * @param  {String} filename 想要保存的文件名称
	 */
	download(url, filename) {
		this.getBlob(url, (blob) => {
			this.saveAs(blob, filename);
		});
	},

	isEmpty: function(obj) {
		if (typeof obj == 'undefined' || obj == null || obj == '') {
			return true;
		} else {
			return false;
		}
	},

	isNotEmpty: function(obj) {
		return !this.isEmpty(obj);
	},

	downloadBlobFile: function(res, fileName) {
		if (res instanceof Blob) {
			if (window.navigator.msSaveBlob) {
				navigator.msSaveBlob(res, fileName);
			} else {
				const blob = res;
				const a = document.createElement('a');
				a.href = URL.createObjectURL(blob);
				a.download = fileName; // 这里填保存成的文件名
				a.click();
				URL.revokeObjectURL(a.href);
			}
		} else {
			console.log('error');
		}
	},

	getQueryStringByName: function(name) {
		var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
		var r = window.location.search.substr(1).match(reg);
		var context = '';
		if (r != null) {
			context = r[2];
		}
		reg = null;
		r = null;
		return context === null || context === '' || context === 'undefined' ? '' : context;
	},

	formatDate: {
		format: function(date, pattern) {
			pattern = pattern || DEFAULT_PATTERN;
			return pattern.replace(SIGN_REGEXP, function($0) {
				switch ($0.charAt(0)) {
					case 'y':
						return padding(date.getFullYear(), $0.length);
					case 'M':
						return padding(date.getMonth() + 1, $0.length);
					case 'd':
						return padding(date.getDate(), $0.length);
					case 'w':
						return date.getDay() + 1;
					case 'h':
						return padding(date.getHours(), $0.length);
					case 'm':
						return padding(date.getMinutes(), $0.length);
					case 's':
						return padding(date.getSeconds(), $0.length);
				}
			});
		},

		parse: function(dateString, pattern) {
			var matchs1 = pattern.match(SIGN_REGEXP);
			var matchs2 = dateString.match(/(\d)+/g);
			if (matchs1.length === matchs2.length) {
				var _date = new Date(1970, 0, 1);
				for (var i = 0; i < matchs1.length; i++) {
					var _int = parseInt(matchs2[i]);
					var sign = matchs1[i];
					switch (sign.charAt(0)) {
						case 'y':
							_date.setFullYear(_int);
							break;
						case 'M':
							_date.setMonth(_int - 1);
							break;
						case 'd':
							_date.setDate(_int);
							break;
						case 'h':
							_date.setHours(_int);
							break;
						case 'm':
							_date.setMinutes(_int);
							break;
						case 's':
							_date.setSeconds(_int);
							break;
					}
				}
				return _date;
			}
			return null;
		},
	},

	//根据区间获得随机数
	getRandom: function(n, m) {
		var n = Number(n); //强制转换成数字
		var m = Number(m);
		if (isNaN(n) || isNaN(m)) {
			//判断是否为有效数字 ，其中一个不是有效数字就返回[0,1)之间的随机小数
			return math.random();
		}
		if (n > m) {
			//如果n>m则交换
			var temp = n;
			n = m;
			m = temp;
		}
		return Math.round(Math.random() * (m - n) + n);
	},

	//生成编号
	createCode: function() {
		var date = new Date();
		var year = date.getFullYear();
		var month = date.getMonth() + 1;
		var strDate = date.getDate();
		if (month >= 1 && month <= 9) {
			month = '0' + month;
		}
		if (strDate >= 0 && strDate <= 9) {
			strDate = '0' + strDate;
		}
		var code = '' + year + month + strDate + this.getRandom(1000, 9999);
		return code;
	},

	//数组去重
	arrUnique: function(arr) {
		for (var i = 0, len = arr.length; i < len; i++) {
			for (var j = i + 1, len = arr.length; j < len; j++) {
				if (arr[i] === arr[j]) {
					arr.splice(j, 1);
					j--; // 每删除一个数j的值就减1
					len--; // j值减小时len也要相应减1（减少循环次数，节省性能）
					// console.log(j,len)
				}
			}
		}
		return arr;
	},

	deepClone(target) {
		// 定义一个变量
		let result;
		// 如果当前需要深拷贝的是一个对象的话
		if (typeof target === 'object') {
			// 如果是一个数组的话
			if (Array.isArray(target)) {
				result = []; // 将result赋值为一个数组，并且执行遍历
				for (let i in target) {
					// 递归克隆数组中的每一项
					result.push(this.deepClone(target[i]));
				}
				// 判断如果当前的值是null的话；直接赋值为null
			} else if (target === null) {
				result = null;
				// 判断如果当前的值是一个RegExp对象的话，直接赋值
			} else if (target.constructor === RegExp) {
				result = target;
			} else {
				// 否则是普通对象，直接for in循环，递归赋值对象的所有值
				result = {};
				for (let i in target) {
					result[i] = this.deepClone(target[i]);
				}
			}
			// 如果不是对象的话，就是基本数据类型，那么直接赋值
		} else {
			result = target;
		}
		// 返回最终结果
		return result;
	},

	getSeriesItemMax(array) {
		console.log(array);
		var res = [];
		array.forEach((item) => {
			item.forEach((i, idx) => {
				if (!res[idx]) {
					if (isNaN(i / 1)) {
						res[idx] = 0;
					} else {
						res[idx] = i / 1;
					}
				} else {
					if (isNaN(i / 1)) {
						res[idx] += 0;
					} else {
						res[idx] += i / 1;
					}
				}
			});
		});
		return Math.max.apply(null, res);
	},
  
	// 刻度最大值
	yAxisMax(maxValue) {
		if (isNaN(maxValue / 1) || maxValue / 1 < 10) {
			return 10;
		}
		const max = Math.ceil(maxValue) + '';
		const itemValue = Math.ceil(max / 5) + '';
		const mins = Math.ceil(itemValue / Math.pow(10, itemValue.length - 1));
		const item = mins * Math.pow(10, itemValue.length - 1) + '';
		const res = Math.ceil(item / 5) * 5 * 5;
		return res;
	},
};
