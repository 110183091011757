<template>
  <div class="report-detail">
    <!-- 返回键 -->
    <div
      class="btn-back"
      @click="back()"
    >
      <i class="el-icon-arrow-left"></i>
      <span>{{$t('Back')}}</span>
    </div>
    <!-- 详情内容 -->
    <div class="report-detail-content">
      <div class="report-detail-among">
        <!-- 第一部分 -->
        <div class="report-title">{{reportData.reportTitle}}</div>
        <div class="report-detail-line">
          <font class="report-write">{{$t('Writer')}}:</font><span class="report-name">{{reportData.userName}}</span>
          <font class="creat-time">{{$t('CreateTime')}}:</font> <span class="report-time">{{reportData.createTime
}}</span>
        </div>
        <hr>
      </div>
      <!-- 中间部分 -->
      <div class="report-middle-part">
        <span>{{$t('BriefIntroduction')}}：</span>
<!--        <div>
          {{reportData.reportDetail}}
        </div>-->
        <pre>
          {{reportData.reportDetail}}
        </pre>
      </div>

      <!-- 底部链接
      
            @click="downloadFile(item)" -->
      <div class="report-detail-buttom">
        <ul v-if="reportData.fileAppendixList">
          <li
            class="appendix"
            v-for="(item, index) in reportData.fileAppendixList"
            :key="index"
          >
            <i class="el-icon-paperclip"></i>
            <a href="javascript:;" @click="handleDownload(item)">{{item}}</a>
            <!-- <span>附件{{ index + 1}}行业报告.dpf</span> -->
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/assets/js/mixin";
import util from "@/assets/js/util.js";
import api from "@/fetch/api";
export default {
  name: "ReportDetail",
  mixins: [mixin],
  components: {},
  data() {
    return {
      reportData: {},
      reportId:this.$route.query.reportId
    };
  },
  created() {
    this.getRepostSessionStorageData();
  },
  mounted() {
    // console.log('this.reportId ->',this.reportId)
  },
  methods: {
    back() {
      this.$router.back();
    },
    getRepostSessionStorageData() {
      api.getReportDetail({
        reportId:this.reportId
      }).then(res=>{
        // console.log('res.data ->',res.data)
        this.reportData = res.data
      })
    },

    //下载文件
    handleDownload(item) {
      util.download(item, this.reportData.reportTitle);
    },
  },
};
</script>

<style lang="scss" scoped>
pre{
  white-space: pre-wrap;
  word-wrap: break-word;
}
.report-detail {
  width: 98%;
  /*.btn-back {
    display: flex;
    flex-flow: row nowrap;
    align-content: center;
    color: #7b7b7b;
    cursor: pointer;
    i {
      font-size: 25px;
      margin: 0 10px 20px 0;
    }
  }*/
  // .el-icon-arrow-left {
  //   position: relative;
  //   top: -12px;
  //   left: 10px;
  //   font-weight: bold;
  //   font-size: 22px;
  // }
  .report-detail-content {
    background-color: #afd2fa;
    margin-top: 20px;
    min-height: 700px;
    display: flex;
    flex-flow: column nowrap;
    //align-items: center;
    
    .report-detail-among {
      display: flex;
      flex-direction: column;
      .report-title {
        font-size: 25px;
        padding: 40px;
      }
      .report-detail-line {
        margin-left: 40px;
        margin-top: 20px;
        .report-write {
          font-size: 14px;
        }
        .report-name {
          margin: 10px;
          font-size: 13px;
        }
        .creat-time {
          font-size: 14px;
          margin: 20px;
        }
        .report-time {
          margin: -5px;
          font-size: 13px;
        }
      }
    }
    hr {
      border: 1px solid #b8c0ca;
      width: 450px;
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  .report-middle-part {
    min-height: 280px;
    padding: 0 35px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    > span {
      font-size: 18px;
    }
    > div {
      font-size: 17px;
      margin-left: 30px;
      margin-top: 20px;
      width: 850px;
      // 字体之间的间距
      letter-spacing: 1px;
    }
  }
  .report-detail-buttom {
    padding: 30px 55px 30px 50px;
    margin-top: 30px;
    .appendix {
      margin-bottom: 15px;
      cursor: pointer;
      & > .icon-fujian {
        border: 1px solid red;
        width: 200px;
        color: #8c939d;
      }
      & > span {
        margin-left: 20px;
        font-size: 18px;
      }
    }
    li {
      list-style: none;
    }
    .appendix:last-child {
      margin-bottom: 0;
    }
    .appendix:hover {
      color: #409fff;
    }
  }
}
</style>